<template>
    <div id="article">
        <header>
            <img :src="image" :alt="`Image of ${title}`">
        </header>
        <div class="container">
            <div class="wrapper">
                <nav class="breadcrumbs">
                    <router-link to="/">Home</router-link>
                    <router-link to="/blog">Blog</router-link>
                    <router-link :to="`/blog/${identifier}`">{{ title }}</router-link>
                </nav>
                <article>
                    <h1>{{ title }}</h1>
                    <div class="content" v-html="content"></div>
                </article>
                <div class="dates">
                    <span class="published title">Published:</span><span class="published"> {{ createdText }}</span><br>
                    <span class="published title">Modified:</span><span class="published"> {{ modifiedText }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Article",
    data: () => ({
        blog: {
            thumbnail: {}
        },
    }),
    async created() {
        await this.$axios.post("collections/get/blog",
            JSON.stringify({fields: {filter: {published: true, created: this.created, title_slug: this.slug}}})
        ).then(this.onBlog).catch(this.noBlog)
    },
    computed: {
        identifier() {
            return this.$route.params?.identifier ?? ""
        },
        created() {
            return Number.parseInt(`${this.identifier}`.split("-")[0] ?? "0")
        },
        createdDate() {
            return new Date(this.created * 1000)
        },
        createdText() {
            return this.getDate(this.createdDate)
        },
        modified() {
            return this.blog?._modified ?? null
        },
        modifiedDate() {
            return new Date(this.modified * 1000)
        },
        modifiedText() {
            return this.getDate(this.modifiedDate)
        },
        title() {
            return this.blog?.title ?? null
        },
        slug() {
            return `${this.identifier}`.replace(`${this.created}`, "").substr(1)
        },
        content() {
            return this.blog?.content ?? null
        },
        image() {
            return `${process.env.VUE_APP_CMS_ROOT}${this.blog?.thumbnail?.path ?? null}`
        },
    },
    methods: {
        onBlog(result) {
            this.blog = (result?.data?.entries ?? [])[0] ?? null
        },
        noBlog(result) {
            console.error(result)
            this.blog = null
        },
        getDate(date) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
            const day = `00${date.getDate()}`.substr(-2)
            const month = months[date.getMonth()].substr(0, 3)
            return `${day} ${month}, ${date.getFullYear()}`
        },
    }

}
</script>
<style lang="sass" scoped>
@import "src/assets/style/views/Article"
</style>
